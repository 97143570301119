import { useMemo } from "react";

function TextFromURL() {

  const windowHref = window.location.href
  const firstParamIndex = windowHref.indexOf('?')
  const windowParamText = firstParamIndex > -1 ? windowHref.substr(firstParamIndex) : ''
  const windowParam = new URLSearchParams(windowParamText);
  const windowParams = windowParam.entries()

  const getItems = useMemo(() => {
    return Array.from(windowParams)
  }, [windowParams])

  return (
    <div>
      {
        getItems.map(item => {
          return <div>
            <p><strong>{decodeURI(item[0])}</strong> {decodeURI(item[1])}</p>
          </div>
        })
      }
    </div>
  );
}

export default TextFromURL;
